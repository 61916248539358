export enum LocationKeys {
  INTERNAL = 'internal',
}

export enum EpisodeLocationKeys {
  EPISODE_ID = 'episodeId',
  PODCAST_ID = 'podcastId',
  START_SEC = 't',
  UTM_CAMPAIGN = 'utm_campaign',
  UTM_CONTENT = 'utm_content',
  UTM_MEDIUM = 'utm_medium',
  UTM_SOURCE = 'utm_source',
  DTM_CAMPAIGN = 'dtm_campaign',
  DTM_CONTENT = 'dtm_content',
  DTM_MEDIUM = 'dtm_medium',
  DTM_SOURCE = 'dtm_source',
  SOURCE = 'source',
}

export enum PodcastLocationKeys {
  PODCAST_ID = 'podcastId',
}

export enum PodcastLegacyLocationKeys {
  FEED_ID = 'feedId',
}
