import WhenNotPreRendering from 'components/WhenNotPreRendering';
import React from 'react';

const Fullstory = React.lazy(() => import('./Fullstory'));

export interface FullstoryContainerProps {}

const FullstoryContainer: React.FC<FullstoryContainerProps> = () => (
  <WhenNotPreRendering>{() => <Fullstory />}</WhenNotPreRendering>
);

export default FullstoryContainer;
