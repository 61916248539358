import { Menu } from '@sparemin/blockhead';
import { useAudioPlayer } from 'pages/AudioPlayerPage/state/AudioPlayerContext';
import { PlaybackRate } from 'pages/AudioPlayerPage/state/AudioPlayerContext/useAudioPlayerControls/types';
import React, { Key, useCallback } from 'react';
import PlaybackRateMenuTrigger from './PlaybackRateMenuTrigger';

export const PLAYBACK_RATES = [3, 2.5, 2, 1.5, 1] as const;

type PlaybackRateMenuProps = {};

const PlaybackRateMenu: React.FC<PlaybackRateMenuProps> = () => {
  const { player, controls } = useAudioPlayer();

  const items = PLAYBACK_RATES.map((rate) => (
    <Menu.Item key={rate}>{`${rate}x speed`}</Menu.Item>
  ));

  const handleAction = useCallback(
    (val: Key) => {
      const numberVal = typeof val === 'number' ? val : parseFloat(val);
      controls?.setPlaybackRate(numberVal as PlaybackRate);
    },
    [controls],
  );

  return (
    <Menu
      button={<PlaybackRateMenuTrigger />}
      onAction={handleAction}
      density="comfortable"
      placement="top"
      offset={16}
      selectionMode="single"
      defaultSelectedKeys={[String(player?.playbackRate)]}
    >
      {items}
    </Menu>
  );
};
export default PlaybackRateMenu;
