import { listeningQueueKeys } from 'api/hooks/queries';
import {
  getListeningQueue,
  ListeningQueueResponse,
  ListeningQueueArgs,
} from 'api/podcast';
import { AxiosError } from 'axios';
import { isFinite } from 'lodash';
import { useQuery } from 'react-query';
import { UseQueryOptions } from 'types';
import UNIQUE_SESSION_ID from 'utils/user';
import { episodeQueueSelector, nextEpisodeSelector } from './selectors';
import { QueueEpisode } from './types';

export type Options<TData = ListeningQueueResponse> = UseQueryOptions<
  ListeningQueueResponse,
  ReturnType<typeof listeningQueueKeys.episode>,
  AxiosError | Error,
  TData
>;

export type UseGetListeningQueueArgs = Partial<
  Omit<ListeningQueueArgs, 'anonymousUserToken'>
>;

function useGetListeningQueue<TData = ListeningQueueResponse>(
  args: UseGetListeningQueueArgs,
  opts?: Options<TData>,
) {
  return useQuery(
    listeningQueueKeys.episode({
      remoteEpisodeId: args.remoteEpisodeId as string,
      podcastId: args.podcastId as string,
      publishedAtMillis: args.publishedAtMillis as number,
      isPlayedFromQueue: args.isPlayedFromQueue,
    }),
    ({ queryKey: [{ args: queryArgs }] }) => {
      const {
        remoteEpisodeId,
        podcastId,
        isPlayedFromQueue,
        publishedAtMillis,
      } = queryArgs;

      return getListeningQueue({
        remoteEpisodeId: remoteEpisodeId!,
        podcastId: podcastId!,
        anonymousUserToken: UNIQUE_SESSION_ID,
        isPlayedFromQueue,
        publishedAtMillis: publishedAtMillis!,
      });
    },
    {
      ...opts,
      staleTime: Infinity,
      enabled:
        !!args.remoteEpisodeId &&
        !!args.podcastId &&
        isFinite(args.publishedAtMillis),
    },
  );
}

export function useQueueEpisodes(args: UseGetListeningQueueArgs) {
  return useGetListeningQueue<QueueEpisode[]>(args, {
    select: episodeQueueSelector,
  });
}

export function useNextEpisodeInQueue(args: UseGetListeningQueueArgs) {
  return useGetListeningQueue(args, { select: nextEpisodeSelector });
}
