import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { MediaElementAudioPlayer } from 'utils/audio';
import { BaseAudioPlayer } from 'utils/audio/types';
import { millisToSec } from 'utils/time';
import { useGetAudioDetails } from '../../useGetEpisodeDetails';
import { AudioPlayerProvider, useAudioPlayer } from '../AudioPlayerContext';
import {
  EpisodeAudioPlayerContextType,
  EpisodeAudioPlayerProviderProps,
  LoadPlayerAudio,
  PlayerIds,
  SponsoredEpisodeState,
  UseEpisodeAudioPlayerResult,
} from './types';

const EpisodeAudioPlayerContext = React.createContext<
  EpisodeAudioPlayerContextType | undefined
>(undefined);

// this component wraps an AudioPlayerProvider and initializes it with audio data from
// a podcast episode
export const EpisodeAudioPlayerProvider: React.FC<
  EpisodeAudioPlayerProviderProps
> = ({ audioElement, children }) => {
  const [player, setPlayer] = useState<BaseAudioPlayer>();
  const [playerIds, setPlayerIds] = useState<PlayerIds>();
  const [sponsoredEpisode, setSponsoredEpisode] =
    useState<SponsoredEpisodeState>({
      isEpisodeSponsored: false,
      sponsoredEpisodeWidgetInfo: undefined,
    });
  const [previousAudioEl, setPreviousAudioEl] =
    useState<HTMLAudioElement | null>();

  const playbackStartTime = useRef<number>();
  const defaultPlaybackRate = useRef(1);

  const { data } = useGetAudioDetails({
    episodeId: playerIds?.episodeId,
    podcastId: playerIds?.podcastId,
  });
  // chapters reference has to be stable and not change unnecessarily since that
  // will cause some issues with context consumers
  const chapters = useMemo(() => data?.chapters, [data?.chapters]);

  const hasNewAudioElement = audioElement && audioElement !== previousAudioEl;

  if (hasNewAudioElement && audioElement.src && playerIds?.episodeId) {
    setPlayer(
      new MediaElementAudioPlayer(audioElement, {
        startTime: playbackStartTime.current,
        playbackRate: defaultPlaybackRate.current,
      }),
    );
    setPreviousAudioEl(audioElement);
  }

  const handleLoadPlayerAudio = useCallback(
    ({
      episodeId,
      podcastId,
      startTime = 0,
      isSponsored = false,
      sponsoredWidgetInfo,
    }: LoadPlayerAudio): void => {
      if (player?.playbackRate) {
        defaultPlaybackRate.current = player?.playbackRate;
      }
      setPlayer(undefined);
      setPlayerIds({ podcastId, episodeId });
      playbackStartTime.current = startTime;

      setSponsoredEpisode({
        isEpisodeSponsored: isSponsored,
        sponsoredEpisodeWidgetInfo: sponsoredWidgetInfo,
      });
    },
    [player?.playbackRate],
  );

  return (
    <EpisodeAudioPlayerContext.Provider
      value={useMemo(
        () => ({
          loadPlayerAudio: handleLoadPlayerAudio,
        }),
        [handleLoadPlayerAudio],
      )}
    >
      <AudioPlayerProvider
        chapters={chapters}
        defaultDuration={millisToSec(data?.audioDurationMillis)}
        episodeId={playerIds?.episodeId}
        player={player}
        podcastId={playerIds?.podcastId}
        isEpisodeSponsored={sponsoredEpisode.isEpisodeSponsored}
        sponsoredEpisodeWidgetInfo={sponsoredEpisode.sponsoredEpisodeWidgetInfo}
      >
        {children}
      </AudioPlayerProvider>
    </EpisodeAudioPlayerContext.Provider>
  );
};

export function useEpisodeAudioPlayer(): UseEpisodeAudioPlayerResult {
  const context = useContext(EpisodeAudioPlayerContext);
  const audioPlayerContext = useAudioPlayer();

  if (context === undefined) {
    throw new Error(
      'useEpisodeAudioPlayer must be used within EpisodeAudioPlayerProvider',
    );
  }

  return {
    ...context,
    ...audioPlayerContext,
  };
}
