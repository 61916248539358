import useGetPodcastEpisodeDetail, {
  useIsBlockedEpisode,
} from 'api/hooks/useGetPodcastEpisodeDetail';
import { is404 } from 'api/utils';
import { useEventTracking } from 'context/EventTrackingContext';
import { useRoutingError } from 'pages/RoutingErrorPage';
import { useEffect, useState } from 'react';
import EpisodePage from './EpisodePage';
import EpisodePageSkeleton from './EpisodePageSkeleton';
import useBlockedEpisode from './hooks/useBlockedEpisode';
import useEpisodePageLocation from './hooks/useEpisodePageLocation';

export interface EpisodePageContainerProps {}

const EpisodePageContainer: React.FC<EpisodePageContainerProps> = () => {
  const {
    startSec,
    internal,
    episodeId,
    podcastId,
    dtmCampaign,
    dtmContent,
    dtmMedium,
    dtmSource,
    source,
  } = useEpisodePageLocation();

  const { setRoutingError } = useRoutingError();
  const { trackEpisodePageLoad } = useEventTracking();

  // episodes are considered blocked if they are individually marked as blocked
  // or if they are part of a feed that is marked as blocked, so it is not sufficient
  // to check episode.isBlocked.
  const { data: isBlocked } = useIsBlockedEpisode({ podcastId, episodeId });

  useBlockedEpisode({
    podcastId,
    episodeId,
  });

  const [interactionModalShown, setInteractionModalShown] = useState<boolean>();

  const { data } = useGetPodcastEpisodeDetail(
    {
      episodeId,
      podcastId,
    },
    {
      onError: (err) => {
        if (is404(err)) {
          setRoutingError(404);
        }
      },
    },
  );

  // No matter what episodeId and podcastId is used to create the page url, all episode page components
  // should be wired up using the remote ids.
  const remoteEpisodeId = data?.remoteEpisodeId;
  const remotePodcastId = data?.podcast.remotePodcastId;
  const episodeName = data?.title;
  const podcastName = data?.podcast.title;

  // To re-implement chapter logic, hasChapters = data.chapters.length > 0;
  const hasChapters = !data ? undefined : false;

  useEffect(() => {
    if (
      hasChapters !== undefined &&
      podcastName !== undefined &&
      episodeName !== undefined &&
      isBlocked !== undefined &&
      interactionModalShown !== undefined
    ) {
      trackEpisodePageLoad({
        // TODO: if chapters are added back to play, update deepLink to
        // indicate a link to a chapter
        deepLink: false,
        episodeName,
        podcastName,
        showWidgetTrafficOverlay: interactionModalShown,
        privateFeed: isBlocked ?? false,
        dtmCampaign,
        dtmContent,
        dtmMedium,
        dtmSource,
      });
    }
  }, [
    episodeName,
    hasChapters,
    internal,
    podcastName,
    startSec,
    trackEpisodePageLoad,
    isBlocked,
    dtmCampaign,
    dtmContent,
    dtmMedium,
    dtmSource,
    interactionModalShown,
  ]);

  const hasData =
    !!remoteEpisodeId && !!remotePodcastId && !!episodeName && !!podcastName;

  return !hasData ? (
    <EpisodePageSkeleton />
  ) : (
    <EpisodePage
      episodeId={remoteEpisodeId}
      podcastId={remotePodcastId}
      startSec={startSec}
      internal={internal}
      hasChapters={hasChapters}
      episodeName={episodeName}
      podcastName={podcastName}
      handleInteractionModal={setInteractionModalShown}
      source={source}
    />
  );
};

export default EpisodePageContainer;
