import {
  AccountMenu,
  AccountMenuProps,
  Menu,
  Mic,
  Link as BlockheadLink,
  LogoutIcon,
} from '@sparemin/blockhead';
import { useEventTracking } from 'context/EventTrackingContext';

export type UserAccountProps = {
  email: string;
  onLogout: () => void;
};
const UserAccount: React.FC<UserAccountProps> = ({ email, onLogout }) => {
  const { trackPromotePodcast } = useEventTracking();

  const settings: AccountMenuProps['children'] = [
    <Menu.LinkItem
      linkComponent={BlockheadLink}
      inheritColor
      key="promote"
      href="https://make.headliner.app"
      target="_blank"
      icon={<Mic />}
    >
      Promote your podcast
    </Menu.LinkItem>,
    <Menu.Item key="logout" icon={<LogoutIcon />}>
      Log out
    </Menu.Item>,
  ];
  return (
    <AccountMenu
      onAction={(key) => {
        if (key === 'logout') onLogout();
        if (key === 'promote') trackPromotePodcast();
      }}
      placement="bottom left"
      emailAddress={email}
    >
      {settings}
    </AccountMenu>
  );
};
export default UserAccount;
