import usePreviousRef from 'hooks/usePreviousRef';
import qs from 'query-string';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { EpisodePageLocationData, PageLocationState } from 'types';
import { EpisodeLocationKeys, LocationKeys } from 'utils/routes/constants';

interface PageDataState extends EpisodePageLocationData {
  pathname: string;
}

export default function useEpisodePageLocation(): EpisodePageLocationData {
  const params = useParams();

  const locationRef = usePreviousRef(useLocation());
  const navigateRef = usePreviousRef(useNavigate());

  const [pageData] = useState<Omit<PageDataState, 'episodeId' | 'podcastId'>>(
    () => {
      const state = locationRef.current.state as PageLocationState | null;
      const search = qs.parse(locationRef.current.search) as Record<
        string,
        string
      >;
      const rawStartSec = search[EpisodeLocationKeys.START_SEC];

      return {
        pathname: locationRef.current.pathname,
        internal: !!state?.[LocationKeys.INTERNAL],
        source:
          state?.[EpisodeLocationKeys.SOURCE] === 'queue' ? 'queue' : undefined,
        startSec: rawStartSec ? parseFloat(rawStartSec) : undefined,
        utmCampaign: search[EpisodeLocationKeys.UTM_CAMPAIGN],
        utmContent: search[EpisodeLocationKeys.UTM_CONTENT],
        utmMedium: search[EpisodeLocationKeys.UTM_MEDIUM],
        utmSource: search[EpisodeLocationKeys.UTM_SOURCE],
        dtmCampaign: search[EpisodeLocationKeys.DTM_CAMPAIGN],
        dtmContent: search[EpisodeLocationKeys.DTM_CONTENT],
        dtmMedium: search[EpisodeLocationKeys.DTM_MEDIUM],
        dtmSource: search[EpisodeLocationKeys.DTM_SOURCE],
      };
    },
  );

  const {
    utmCampaign,
    utmContent,
    utmMedium,
    utmSource,
    dtmCampaign,
    dtmContent,
    dtmMedium,
    dtmSource,
    pathname,
    internal,
    startSec,
    source,
  } = pageData;

  const path = qs.stringifyUrl({
    url: pathname,
    query: {
      utm_campaign: utmCampaign,
      utm_content: utmContent,
      utm_medium: utmMedium,
      utm_source: utmSource,
      dtm_campaign: dtmCampaign,
      dtm_content: dtmContent,
      dtm_medium: dtmMedium,
      dtm_source: dtmSource,
    },
  });

  // runs on mount to clear data from the url except for the utm and dtm paramaters.
  // everything we need should exist on the first render
  useEffect(() => {
    navigateRef.current(path, {
      replace: true,
      state: null,
    });
  }, [navigateRef, path]);

  return {
    utmCampaign,
    utmContent,
    utmMedium,
    utmSource,
    dtmCampaign,
    dtmContent,
    dtmMedium,
    dtmSource,
    internal,
    startSec,
    episodeId: params[EpisodeLocationKeys.EPISODE_ID],
    podcastId: params[EpisodeLocationKeys.PODCAST_ID],
    source,
  };
}
